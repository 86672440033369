/* CSS declarations go here */

@font-face {
  font-family: 'Brux';
  src: url('./assets/fonts/brux-regular-webfont.eot') format('embedded-opentype'),
  url('./assets/fonts/brux-regular-webfont.woff2') format('woff2'),
  url('./assets/fonts/brux-regular-webfont.woff') format('woff'),
  url('./assets/fonts/brux-regular-webfont.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Comfortaa Regular';
  src: url('./assets/fonts/Comfortaa-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa Bold';
  src: url('./assets/fonts/Comfortaa-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Comfortaa Light';
  src: url('./assets/fonts/Comfortaa-Light.ttf') format('truetype');
}

:root {
--red: #C71D1D;
--endship: #C6F5FF;
--piggly: #D6FDD0;
--imp: #fecc2f;
--meow: brown;
--gray: #4A4A4A;

--blue: #2C415F;
--yellow: #FFB00D;

--logoFont: 'Comfortaa Regular', Helvetica;
--titleFont: 'Comfortaa Bold', Helvetica;
--boldFont: 'Comfortaa Bold', Helvetica;
--textFont: 'Comfortaa Light', 'Avenir Next', Helvetica;
--responsive-width: 800px;
--content-width: 1080px;
}


body, h1, ul, li, strong, p, a, small{
  margin: 0;
  color: var(--blue);
  font-family: var(--textFont);
}
h2{
  color: var(--blue);
  font-family: var(--titleFont);
}

a {
  text-decoration: none;
}

.fa-15x {
  font-size: 1.5em;
}

/*************** Hero **************/

.hero-container {
  align-items: center;
  background: url('./assets/images/resistance.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 900px;
  width: 100%;
}

.logo-section {
  align-items: center;
  display: flex;
}

.logo-section h1 {
  color: var(--blue);
  font-family: var(--logoFont);
  font-size: 45px;
  flex: 5;
}

.logo-section img{
  margin-right: 10px;
}


header {
  align-items: center;
  display: flex;
  margin: 40px auto 0;
  max-width: 90%;
  width: var(--content-width);
}

.menu {
  display: flex;
  flex: 4;
  justify-content: flex-end;
  list-style: none;
}

.menu li {
  margin-left: 20px;
}

.menu li a {
color: white;
    font-family: var(--titleFont);
    text-transform: uppercase;
}

.menu li a:hover {
      color: var(--yellow);
}


.text-container{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.text-container h2 {
  font-family: var(--titleFont);
  font-size: 10vw;
  opacity: 0.3;
  color: var(--yellow);
  text-transform: uppercase;
}

@media screen and (max-width: 800px ){
  h1 {
    text-align: center;
  }
  .logo-section {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
}

/*************** Apps **************/

.apps-container {
  margin: 150px auto;
  width: 90%;
}

.app {
  display: flex;
  max-width: 100%;
  width: var(--content-width);
  margin: 50px auto;
}

.app .app-links {
  width: 200px;
}

.endship a:hover i{
  color: var(--yellow);
}

.abuddy-image {
  height: 300px;
}

.meow-image {
  width: 512px;
}
.abby-image {
  width: 212px;
}

.imp a:hover i{
  color: var(--yellow);
}

.meow a:hover i{
  color: var(--yellow);
}

.app-image, .app-content {
  flex: 1;
}

.app.left-image .app-image{
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.app-image img {
  max-width: 100%;
}

.app-content {
  display: flex;
  flex-direction: column;
  
}
.app-content h2 {
  font-size: 50px;
  margin: 0;
  text-decoration: underline;
}
.app-content h4{
  font-size: 14px;
  font-weight: 300;
  margin: 30px 0 5px;
  text-transform: uppercase;
}

.blurb p {
  width: 90%;
}
.blurb a {
  font-family: var(--boldFont);
}

.blurb a:hover {
  color: var(--yellow);
}

.links h4{
  font-family: var(--boldFont);
}

.links a {
  margin-right: 15px;
}


.app-details {
  display: flex;
}

.blurb {
  margin-left: 50px;
  margin-top: 30px;
}

.small-only {
  display: none;
}

@media screen and (max-width: 800px ){
  .app.left-image {
    flex-direction: column;
  }
  .app.right-image {
    flex-direction: column-reverse;
  }
  .app-image {
    margin: 0;
    width: 100%;
  }
  .large-only {
    display: none;
  }
  .small-only {
    display: block;
  }
}

/*************** Footer **************/
footer {
  background-color: var(--blue);
  padding: 30px 0 50px;
  width: 100%;
  
}

footer .footer-container{
  align-items: center;
  display: flex;
  margin: 0 auto;
  width: var(--content-width);
  max-width: 90%;
}

footer .footer-logo img{
  width: 180px;
  margin-bottom: 15px;
}

footer h2, footer p, footer a {
  color: white;
}
p {
  /* text-transform: uppercase; */
  font-size: 14px;
}

footer .left-side, footer .right-side{
  flex: 1;
  
}
footer .left-side h3, footer.right-side h3 {
    color: var(--blue);
    font-family: var(--logoFont);
    font-size: 24px;
    margin: 0 0 20px 0;
  }

footer .left-side{
  align-items: center;
  display: flex;
}
footer .left-size  img {
  margin-right: 15px;
}

footer .social-links {
  margin-bottom: 20px;
  
}
footer .social-links a {
    margin-left: 15px;
  }

footer .social-links a:hover {
    color: var(--yellow);
}




footer .right-side {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: right;
}
p span {
  color: var(--yellow);
}

@media screen and (max-width: 800px ){
  .footer-container {
    flex-direction: column;
    text-align: center;
    
  }
.footer-container .left-side {
      flex-direction: column;
    }

    .footer-container .left-side img {

        margin-right: 0;
    }
.footer-container .social-links {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  text-align: center;

}

.footer-container .social-links a {
  margin-left: 0;
}

.footer-container .social-links a:hover {
    color: var(--yellow);
}
}
